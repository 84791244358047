import {Map, Record} from 'immutable';

export interface PreadmissionListState extends Map<string, any> {
    preadmissionlist: any;
    renewalDetails: any;
  

    preadmissionlistLoading: boolean;
    preadmissionlistLoaded: boolean;
    preadmissionlistFailed: boolean;

    renewalDetailsLoading: boolean;
    renewalDetailsLoaded: boolean;
    renewalDetailsFailed: boolean;

    admissionPaymentDetails:  any;
    admissionPaymentDetailsLoading: boolean;
}

export const PreadmissionListStateRecord = Record({
    preadmissionlist: [],
    renewalDetails: [],
    preadmissionlistLoading: false,
    preadmissionlistLoaded: false,
    preadmissionlistFailed: false,

    renewalDetailsLoading: false,
    renewalDetailsLoaded: false,
    renewalDetailsFailed: false,

    admissionPaymentDetails:  {},
    admissionPaymentDetailsLoading: false
});
