import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
//import { CONTAINERS } from './common/common-layout.module';
// import { ChangePasswordComponent } from './pages/change-password/change-password.component';
// import { InvalidStaffsComponent } from './pages/invalid-staffs/invalid-staffs.component';
import { AuthGuard } from './providers/guards/auth.guard';


const routes: Routes = [
  // {
  //   path: 'auto-login/:id',
  //   loadChildren: './auto-login/auto-login.module#AutoLoginModule'
  // },



  {
    path: 'preadmissionform',
   // canActivate: [AuthGuard],
    loadChildren: './pages/preadmissionform/preadmissionform.module#AdmissionModule'
  },
  {
    path: 'preadmissionform/:id',
   // canActivate: [AuthGuard],
    loadChildren: './pages/preadmissionform/preadmissionform.module#AdmissionModule'
  },
  {
    path: 'login',
   // canActivate: [AuthGuard],
    loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: 'preadmissionlist',
    loadChildren: './pages/preadmissionlist/preadmissionlist.module#preadmissionlistModule'
  },
  {
    path: 'paymentsucccess',
    loadChildren: './pages/payment-success/payment-success.module#PaymentSuccessModule'
  },

//   {
//     path: '',
//     component: CONTAINERS.LayoutComponent,
//     children: [
//       {
//         path: 'dashboard',
//         canActivate: [AuthGuard],
//         loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
//       },
//       {
//         path: 'master-setup',
//         canActivate: [AuthGuard],
//         loadChildren:
//           './pages/master-setup/master-setup.module#MasterSetupModule'
//       },
//       {
//         path: 'staff-management',
//         canActivate: [AuthGuard],
//         loadChildren:
//           './pages/staff-management/staff-management.module#StaffManagementModule'
//       },
//       {
//         path: 'student-management',
//         canActivate: [AuthGuard],
//         loadChildren:
//           './pages/student-management/student-management.module#StudentManagementModule'
//       },
//       {
//         path: 'admission',
//         canActivate: [AuthGuard],
//         loadChildren: './pages/admission/admission.module#AdmissionModule'
//       },
//       {
//         path: 'change-password',
//         canActivate: [AuthGuard],
//         component: ChangePasswordComponent
//       },
//       {
//         path: 'invalid-staffs',
//         canActivate: [AuthGuard],
//         component: InvalidStaffsComponent
//       },
//       {
//         path: 'messages',
//         canActivate: [AuthGuard],
//         loadChildren: './pages/messages/messages.module#MesssagesModule'
//       },
//       {
//         path: 'vehicle-management',
//         canActivate: [AuthGuard],
//         loadChildren:
//           './pages/vehicle-management/vehicle-management.module#VehicleManagementModule'
//       },
//       {
//         path: 'fees-management',
//         canActivate: [AuthGuard],
//         loadChildren:
//           './pages/fee-management/fee-management.module#FeeManagementModule'
//       },
//       {
//         path: 'report',
//         canActivate: [AuthGuard],
//         loadChildren: './pages/report/report.module#ReportModule'
//       },
//       {
//         path: 'certificates',
//         canActivate: [AuthGuard],
//         loadChildren: './pages/certificates/certificates.module#CertificatesModule'
//       },
//       {
//         path: 'Mytest',
//         canActivate: [AuthGuard],
//         loadChildren: './pages/Mytest/mytest.module#MytestModule'
//       },
//       {
//         path: 'idcard',
//         canActivate: [AuthGuard],
//         loadChildren: './pages/id-card-generator/id-card.module#IdCardModule'
//       },
//       {
//         path: 'feesreport',
//         canActivate: [AuthGuard],
//         loadChildren: './pages/fees-report/fees-report.module#FeesReportModule'
//       },
//       {
//         path: 'activity-log',
//         canActivate: [AuthGuard],
//         loadChildren:
//           './pages/activity-log/activity.module#ActivityModule'
//       },
//       {
//         path: 'inventory',
//         loadChildren: './pages/inventory/inventory.module#InventoryModule'
//       },
//       {
//         path: 'payroll',
//         loadChildren: './pages/payroll-management/payroll-management.module#PayrollManagementModule'
//       },
//       {
//         path: 'attendance',
//         loadChildren: './pages/attendance/attendance.module#AttendanceModule'
//       },
//       {
//         path: 'reports',
//         loadChildren: './pages/reports/reports.module#ReportsModule'
//       },
//       {
//         path: '',
//         canActivate: [AuthGuard],
//         redirectTo: 'dashboard',
//         pathMatch: 'full'
//       }
//     ]
//   },

//   {
//     path: 'auth',
//     canActivate: [AuthGuard],
//     loadChildren: './auth/auth.module#AuthModule'
//   },
 ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
          preloadingStrategy: PreloadAllModules,
          scrollPositionRestoration: 'enabled'
        }
    )],
      // RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
