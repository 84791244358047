import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import {CommonLayoutModule, CONTAINERS} from './common/common-layout.module';
//import {SharedModule} fro/m './shared/shared.module';
import { NgxPrintModule } from 'ngx-print';


// components
import {AppComponent} from './app.component';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './app.reducer';
import {EffectsModule} from '@ngrx/effects';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {RequestInterceptor} from './providers/interceptor/request.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//import {ChangePasswordComponent} from './pages/change-password/change-password.component';
import {AuthGuard} from './providers/guards/auth.guard';
import {
    HashLocationStrategy,
    LocationStrategy
} from '@angular/common';
//import {NumberAcceptModule} from './shared/validation-directives/onlyNumber.module';
// import {GroupsSandbox} from './pages/master-setup/groups/groups.sandbox';
// import { InvalidStaffsComponent } from './pages/invalid-staffs/invalid-staffs.component';
// import {ResetFormComponent} from './shared/popup/reset-form/reset-form.component';
import {DynamicScriptLoaderService} from './providers/services/Dynamic.Script.Loader.service';
import {RouterAuthGard} from './providers/guards/router.auth.gard';
import { ChartsModule } from 'ng2-charts';
import * as $ from 'jquery';
// import {AutoLoginService} from './auto-login/auto-login.service';
// import {AutoLoginSandbox} from './auto-login/auto-login.sandbox';
// import {AutoLoginEffect} from './auto-login/effects/auto-login.effect';
// import {AuthEffect} from './auth/effects/auth.effect';





const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto'
};

@NgModule({
    declarations: [
        AppComponent,
        
      //  ChangePasswordComponent,
      //  CONTAINERS.LayoutComponent,
       // InvalidStaffsComponent,
       // ResetFormComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        SwiperModule,
        AppRoutingModule,
       // CommonLayoutModule,
       // SharedModule,
        HttpClientModule,
        ChartsModule,
        StoreModule.forRoot(reducers, {metaReducers}),
        EffectsModule.forRoot([]),
        // EffectsModule.forRoot([AutoLoginEffect]),
        //NumberAcceptModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            easing: 'ease-in',
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            closeButton: true,
        }),
        NgxPrintModule,
    ],
    providers: [
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
        AuthGuard,
        RouterAuthGard,
      // GroupsSandbox,
        DynamicScriptLoaderService,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
       // ResetFormComponent
    ]
})
export class AppModule {
}
