import { Action } from '@ngrx/store';
import { type } from '../../../shared/utility';
import {ResponseInterface} from '../../../shared/interfaces/interface';

export const ActionTypes = {
  PREADMISSION_LIST: type('[PREADMISSIONLIST] PREADMISSIONLIST'),
  PREADMISSION_LIST_SUCCESS: type('[PREADMISSIONLIST] PREADMISSIONLIST Success'),
  PREADMISSION_LIST_FAIL: type('[PREADMISSIONLIST] PREADMISSIONLIST Fail'),
  // renewal details
  RENEWAL_DETAILS_LIST: type('[PREADMISSIONLIST] RENEWAL_DETAILS_LIST'),
  RENEWAL_DETAILS_LIST_SUCCESS: type('[PREADMISSIONLIST] RENEWAL_DETAILS_LIST Success'),
  RENEWAL_DETAILS_LIST_FAIL: type('[PREADMISSIONLIST] RENEWAL_DETAILS_LIST Fail'),

  UPDATE_PAYMENT_STATUS: type('[PREADMISSIONLIST] UPDATE_PAYMENT_STATUS'),
  UPDATE_PAYMENT_STATUS_SUCCESS: type('[PREADMISSIONLIST] UPDATE_PAYMENT_STATUS_SUCCESS Success'),
  UPDATE_PAYMENT_STATUS_FAIL: type('[PREADMISSIONLIST] UPDATE_PAYMENT_STATUS_FAIL Fail'),
  
  ADMISSION_PAYMENT_DETAILS: type('[PREADMISSIONLIST] ADMISSION_PAYMENT_DETAILS'),
  ADMISSION_PAYMENT_DETAILS_SUCCESS: type('[PREADMISSIONLIST] ADMISSION_PAYMENT_DETAILS_SUCCESS Success'),
  ADMISSION_PAYMENT_DETAILS_FAIL: type('[PREADMISSIONLIST] ADMISSION_PAYMENT_DETAILS_FAIL Fail'),
};

/**
 * login
 */
export class preadmissionlist implements Action {
  type = ActionTypes.PREADMISSION_LIST;
  constructor(public payload: any) {}
}

export class preadmissionlistSuccess implements Action {
  type = ActionTypes.PREADMISSION_LIST_SUCCESS;
  constructor(public payload: any) {
  }
}
export class preadmissionlistFail implements Action {
  type = ActionTypes.PREADMISSION_LIST_FAIL;
  constructor(public payload: any) {}
}
// renewal details
export class RenewalDetails implements Action {
  type = ActionTypes.RENEWAL_DETAILS_LIST;
  constructor(public payload: any) {}
}

export class RenewalDetailsSuccess implements Action {
  type = ActionTypes.RENEWAL_DETAILS_LIST_SUCCESS;
  constructor(public payload: ResponseInterface) {
  }
}
export class RenewalDetailsFail implements Action {
  type = ActionTypes.RENEWAL_DETAILS_LIST_FAIL;
  constructor(public payload: any) {}
}

export class updateApplicationPaymentStatus implements Action {
  type = ActionTypes.UPDATE_PAYMENT_STATUS;
  constructor(public payload: any) {}
}

export class updateApplicationPaymentStatusSuccess implements Action {
  type = ActionTypes.UPDATE_PAYMENT_STATUS_SUCCESS;
  constructor(public payload: any) {
  }
}
export class updateApplicationPaymentStatusFail implements Action {
  type = ActionTypes.UPDATE_PAYMENT_STATUS_FAIL;
  constructor(public payload: any) {}
}
export class admissionPaymentDetails implements Action {
  type = ActionTypes.ADMISSION_PAYMENT_DETAILS;

  constructor(public payload: any) {
  }
}

export class admissionPaymentDetailsSuccess implements Action {
  type = ActionTypes.ADMISSION_PAYMENT_DETAILS_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class admissionPaymentDetailsFail implements Action {
  type = ActionTypes.ADMISSION_PAYMENT_DETAILS_FAIL;

  constructor(public payload: any) {
  }
}

export type Actions =
  | preadmissionlist
  | preadmissionlistSuccess
  | preadmissionlistFail
  | updateApplicationPaymentStatus
  | updateApplicationPaymentStatusSuccess
  | updateApplicationPaymentStatusFail;
