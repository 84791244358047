export const environment = {
  production: true,


  sampleUrl: 'https://vs5.voicesnapforschools.com/nodejs/csv',
  fileUrl: 'https://vs5.voicesnapforschools.com/nodejs/api/file-report/file-download',
  baseUrl: 'https://vs5.voicesnapforschools.com/nodejs/api',
  url: 'https://vs6.voicesnapforschools.com',


};
