import { Action } from '@ngrx/store';
import { type } from '../../../shared/utility';
import { ResponseInterface } from '../../../shared/interfaces/interface';
import { PreAdmissionModel } from '../model/preadmissionform.model';

export const ActionTypes = {
    CREATE_PRE_ADMISSION: type('[admission] create pre admission'),
    CREATE_PRE_ADMISSION_SUCCESS: type('[admission] create pre admission Success'),
    CREATE_PRE_ADMISSION_FAIL: type('[admission] create pre admission Fail'),

    GET_PRE_ADMISSION_LIST: type('[admission] get preadmission list'),
    GET_PRE_ADMISSION_LIST_SUCCESS: type('[admission] get preadmission list Success'),
    GET_PRE_ADMISSION_LIST_FAIL: type('[admission] get preadmission list Fail'),

    GET_PRE_ADMISSION_DETAIL: type('[admission] get preadmission detail'),
    GET_PRE_ADMISSION_DETAIL_SUCCESS: type('[admission] get preadmission detail Success'),
    GET_PRE_ADMISSION_DETAIL_FAIL: type('[admission] get preadmission detail Fail'),

    UPDATE_PRE_ADMISSION_DETAIL: type('[admission] update preadmission detail'),
    UPDATE_PRE_ADMISSION_DETAIL_SUCCESS: type('[admission] update preadmission detail Success'),
    UPDATE_PRE_ADMISSION_DETAIL_FAIL: type('[admission] update preadmission detail Fail'),

    DELETE_PRE_ADMISSION: type('[admission] delete pre admission'),
    DELETE_PRE_ADMISSION_SUCCESS: type('[admission] delete pre admission Success'),
    DELETE_PRE_ADMISSION_FAIL: type('[admission] delete pre admission Fail'),

    GENERATE_ADMISSION_NO: type('[admission] generate pre admission no'),
    GENERATE_ADMISSION_NO_SUCCESS: type('[admission] generate pre admission no Success'),
    GENERATE_ADMISSION_NO_FAIL: type('[admission] generate pre admission no Fail'),

    GET_PRE_ADMISSION_COUNT: type('[admission] get pre admission count'),
    GET_PRE_ADMISSION_COUNT_SUCCESS: type('[admission] get pre admission count Success'),
    GET_PRE_ADMISSION_COUNT_FAIL: type('[admission] get pre admission count Fail'),

    CHANGE_PRE_ADMISSION_STATUS: type('[admission] change preadmission status'),
    CHANGE_PRE_ADMISSION_STATUS_SUCCESS: type('[admission] change preadmission status Success'),
    CHANGE_PRE_ADMISSION_STATUS_FAIL: type('[admission] change preadmission status Fail'),

    SELECT_STUDENT: type('[select] select student '),

    ALLOT_SECTION: type('[allot] allot section'),
    ALLOT_SECTION_SUCCESS: type('[allot] allot section Success'),
    ALLOT_SECTION_FAIL: type('[allot] allot section Fail'),


    IMPORT_ADMISSION: type('[import] import admission'),
    IMPORT_ADMISSION_SUCCESS: type('[import] import admission Success'),
    IMPORT_ADMISSION_FAIL: type('[import] import admission Fail'),

    EXPORT_ADMISSION: type('[export] export admission'),
    EXPORT_ADMISSION_SUCCESS: type('[export] export admission Success'),
    EXPORT_ADMISSION_FAIL: type('[export] export admission Fail'),


    DOWNLOAD_STUDENT_TEMPLATE: type('[admission] Download student template'),
    DOWNLOAD_STUDENT_TEMPLATE_SUCCESS: type('[admission] Download student template Success'),
    DOWNLOAD_STUDENT_TEMPLATE_FAIL: type('[admission] Download student template Fail'),

    GENERATE_CASHFREE_ORDER: type('[admission] Generate CashFree Order'),
    GENERATE_CASHFREE_ORDER_SUCCESS: type('[admission] Generate CashFree Order Success'),
    GENERATE_CASHFREE_ORDER_FAIL: type('[admission] Generate CashFree Order Fail'),

    GROUP_CONFIGURE_DETAILS: type('[admission] GROUP_CONFIGURE_DETAILS'),
    GROUP_CONFIGURE_DETAILS_SUCCESS: type('[admission] GROUP_CONFIGURE_DETAILS Success'),
    GROUP_CONFIGURE_DETAILS_FAIL: type('[admission] GROUP_CONFIGURE_DETAILS Fail'),
    
};

/**
 *  create pre admission actions.
 */
export class CreatePreAdmission implements Action {
    type = ActionTypes.CREATE_PRE_ADMISSION;

    constructor(public payload: PreAdmissionModel) {
        console.log('preadmission', payload);
    }
}

export class CreatePreAdmissionSuccess implements Action {
    type = ActionTypes.CREATE_PRE_ADMISSION_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class CreatePreAdmissionFail implements Action {
    type = ActionTypes.CREATE_PRE_ADMISSION_FAIL;

    constructor(public payload: any) {
    }
}
/**
 *  get pre admission count actions.
 */
export class GetPreAdmssionCount implements Action {
    type = ActionTypes.GET_PRE_ADMISSION_COUNT;

    constructor(public payload: any) {
        console.log('preadmission', payload);
    }
}

export class GetPreAdmssionCountSuccess implements Action {
    type = ActionTypes.GET_PRE_ADMISSION_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetPreAdmssionCountFail implements Action {
    type = ActionTypes.GET_PRE_ADMISSION_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

/**
 *  get pre admission list actions.
 */
export class GetPreAdmission implements Action {
    type = ActionTypes.GET_PRE_ADMISSION_LIST;

    constructor(public payload: any) {
    }
}

export class GetPreAdmissionSuccess implements Action {
    type = ActionTypes.GET_PRE_ADMISSION_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetPreAdmissionFail implements Action {
    type = ActionTypes.GET_PRE_ADMISSION_LIST_FAIL;

    constructor(public payload: any) {
    }
}
/**
 *  get pre admission detail actions.
 */
export class GetPreAdmissionDetail implements Action {
    type = ActionTypes.GET_PRE_ADMISSION_DETAIL;
    constructor(public payload: any) {
    }
}

export class GetPreAdmissionDetailSuccess implements Action {
    type = ActionTypes.GET_PRE_ADMISSION_DETAIL_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetPreAdmissionDetailFail implements Action {
    type = ActionTypes.GET_PRE_ADMISSION_DETAIL_FAIL;

    constructor(public payload: any) {
    }
}
/**
 *  update pre admission detail actions.
 */
export class UpdatePreAdmission implements Action {
    type = ActionTypes.UPDATE_PRE_ADMISSION_DETAIL;

    constructor(public payload: any) {
    }
}

export class UpdatePreAdmissionSuccess implements Action {
    type = ActionTypes.UPDATE_PRE_ADMISSION_DETAIL_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class UpdatePreAdmissionFail implements Action {
    type = ActionTypes.UPDATE_PRE_ADMISSION_DETAIL_FAIL;

    constructor(public payload: any) {
    }
}

/**
 *  delete pre admission  actions.
 */
export class DeletePreAdmission implements Action {
    type = ActionTypes.DELETE_PRE_ADMISSION;

    constructor(public payload: any) {
    }
}

export class DeletePreAdmissionSuccess implements Action {
    type = ActionTypes.DELETE_PRE_ADMISSION_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class DeletePreAdmissionFail implements Action {
    type = ActionTypes.DELETE_PRE_ADMISSION_FAIL;

    constructor(public payload: any) {
    }
}
/**
 *  generate pre admission  no.
 */
export class GenerateAdmissionNo implements Action {
    type = ActionTypes.GENERATE_ADMISSION_NO;

    constructor(public payload = null) {
    }
}

export class GenerateAdmissionNoSuccess implements Action {
    type = ActionTypes.GENERATE_ADMISSION_NO_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GenerateAdmissionNoFail implements Action {
    type = ActionTypes.GENERATE_ADMISSION_NO_FAIL;

    constructor(public payload: any) {
    }
}
/**
 *  change pre admission status actions.
 */
export class ChangePreAdmissionStatus implements Action {
    type = ActionTypes.CHANGE_PRE_ADMISSION_STATUS;

    constructor(public payload: any) {
    }
}

export class ChangePreAdmissionStatusSuccess implements Action {
    type = ActionTypes.CHANGE_PRE_ADMISSION_STATUS_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class ChangePreAdmissionStatusFail implements Action {
    type = ActionTypes.CHANGE_PRE_ADMISSION_STATUS_FAIL;

    constructor(public payload: any) {
    }
}
/**
 *  select student for section allotment actions.
 */
export class SelectStudent implements Action {
    type = ActionTypes.SELECT_STUDENT;

    constructor(public payload: any) {
    }
}
/**
 *  change pre admission status actions.
 */
export class AllotSectionAction implements Action {
    type = ActionTypes.ALLOT_SECTION;

    constructor(public payload: any) {
    }
}

export class AllotSectionSuccess implements Action {
    type = ActionTypes.ALLOT_SECTION_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class AllotSectionFail implements Action {
    type = ActionTypes.ALLOT_SECTION_FAIL;

    constructor(public payload: any) {
    }
}


/**
 *  import admission actions.
 */
export class ImportAdmission implements Action {
    type = ActionTypes.IMPORT_ADMISSION;

    constructor(public payload: any) {
    }
}

export class ImportAdmissionSuccess implements Action {
    type = ActionTypes.IMPORT_ADMISSION_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class ImportAdmissionFail implements Action {
    type = ActionTypes.IMPORT_ADMISSION_FAIL;

    constructor(public payload: any) {
    }
}
/**
 *  export admission actions.
 */
export class ExportAdmission implements Action {
    type = ActionTypes.EXPORT_ADMISSION;

    constructor(public payload: any) {
    }
}

export class ExportAdmissionSuccess implements Action {
    type = ActionTypes.EXPORT_ADMISSION_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class ExportAdmissionFail implements Action {
    type = ActionTypes.EXPORT_ADMISSION_FAIL;

    constructor(public payload: any) {
    }
}



export class GenerateCashFreeOrder implements Action {
    type = ActionTypes.GENERATE_CASHFREE_ORDER;
    constructor(public payload: ResponseInterface) {
    }
    
}

export class GenerateCashFreeOrderSuccess implements Action {
    type = ActionTypes.GENERATE_CASHFREE_ORDER_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GenerateCashFreeOrderFail implements Action {
    type = ActionTypes.GENERATE_CASHFREE_ORDER_FAIL;

    constructor(public payload: any) {
    }
}
// GroupConfigureDetails

export class GroupConfigureDetails implements Action {
    type = ActionTypes.GROUP_CONFIGURE_DETAILS;
    constructor(public payload: any) {
    }
    
}

export class GroupConfigureDetailsSuccess implements Action {
    type = ActionTypes.GROUP_CONFIGURE_DETAILS_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GroupConfigureDetailsFail implements Action {
    type = ActionTypes.GROUP_CONFIGURE_DETAILS_FAIL;

    constructor(public payload: any) {
    }
}
// download student template
export class DownloadStuTemplate implements Action {
    type = ActionTypes.DOWNLOAD_STUDENT_TEMPLATE;

}
export class DownloadStuTemplateSuccess implements Action {
    type = ActionTypes.DOWNLOAD_STUDENT_TEMPLATE_SUCCESS;

    constructor(public payload: ResponseInterface) { }
}
export class DownloadStuTemplateFail implements Action {
    type = ActionTypes.DOWNLOAD_STUDENT_TEMPLATE_FAIL;

    constructor(public payload: any) { }
}

export type Actions =
    | CreatePreAdmission
    | CreatePreAdmissionSuccess
    | CreatePreAdmissionFail
    | DownloadStuTemplate
    | DownloadStuTemplateSuccess
    | DownloadStuTemplateFail
    | GenerateAdmissionNo
    | GenerateAdmissionNoSuccess
    | GenerateAdmissionNoFail;

