//import * as authReducer from './auth/reducer/auth.reducer';
//import * as commonReducer from './common/reducer/common.reducer';
import * as PreAdmissionState from './pages/preadmissionform/reducer/preadmissionform.reducer';
import * as StudentManagementState from './pages/student-management/reducers/student-management.reducer'
import * as LoginState from './pages/login/reducer/login.reducer'
import * as PreadmissionListState from './pages/preadmissionlist/reducer/preadmissionlist.reducer'

//import * as autoLogin from './auto-login/reducer/auto-logon.reducer'



import {ActionReducerMap, ActionReducer, MetaReducer} from '@ngrx/store';
import {AppState as State} from './app.state';
import {environment} from '../environments/environment.prod';

export const reducers: ActionReducerMap<State> = {
    //auth:authReducer.reducer,
    //: commonReducer.reducer,
    preAdmission  : PreAdmissionState.reducer,
    studentManagement: StudentManagementState.reducer,
    Login :LoginState.reducer,
    PreadmissionListState:PreadmissionListState.reducer
   // autoLogin: autoLogin.reducer,
 
};

export function logger(reducer: ActionReducer<State>): ActionReducer<any, any> {
    return function (state: State, action: any): State {
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger]
    : [];
